import { render, staticRenderFns } from "./MainDiseases.vue?vue&type=template&id=6fa5b94d&scoped=true"
import script from "./MainDiseases.vue?vue&type=script&lang=js"
export * from "./MainDiseases.vue?vue&type=script&lang=js"
import style0 from "./MainDiseases.vue?vue&type=style&index=0&id=6fa5b94d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa5b94d",
  null
  
)

export default component.exports