<template>
  <div id="glauco-view" class="wh-container">
    <header class="header no-print">
      <p class="title">Glauco</p>
      <small class="mt-2 ml-2">{{ totalPatients }} pacientes cadastrados</small>
    </header>

    <div class="content">
      <b-container fluid>
        <b-row class="content-data">
          <b-col cols="12">
            <v-glauco-resume
              :clinicId="clinic.id"
              :openAccessKeyModal="openAccessKeyModal"
              :newAccessKey="accessKey"
              @change-total-patients="changeTotalPatients"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid>
        <b-row class="content-data">
          <b-col cols="8">
            <v-main-diseases :clinicId="clinic.id" />
          </b-col>
          <v-patient-quantity :clinicId="clinic.id" :action="action" />
        </b-row>
      </b-container>

      <b-container fluid>
        <b-row class="content-data">
          <b-col cols="6">
            <v-adherence-treatment :clinicId="clinic.id" />
          </b-col>
          <b-col cols="6">
            <v-patients-gender :clinicId="clinic.id" />
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid ref="patients">
        <b-row class="content-data">
          <b-col cols="12">
            <v-glauco-patients :clinicId="clinic.id" />
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid ref="patientsWithCompanions">
        <b-row class="content-data">
          <b-col cols="12">
            <v-glauco-patients-with-companion :clinicId="clinic.id" />
          </b-col>
        </b-row>
      </b-container>

      <v-new-access-key-modal :clinic="clinic" @new-access-key="newAccessKey" />
    </div>
  </div>
</template>

<script>
import NewAccessKeyModal from '@/components/Glauco/Dashboard/Modal/NewAccessKeyModal'
import GlaucoResume from '@/layouts/Glauco/Dashboard/GlaucoResume'
import MainDiseases from '@/layouts/Glauco/Dashboard/MainDiseases'
import PatientQuantity from '@/layouts/Glauco/Dashboard/PatientQuantity'
import AdherenceTreatment from '@/layouts/Glauco/Dashboard/AdherenceTreatment'
import PatientsGender from '@/layouts/Glauco/Dashboard/PatientsGender'
import GlaucoPatients from '@/layouts/Glauco/Dashboard/GlaucoPatients'
import GlaucoPatientsWithCompanion from '@/layouts/Glauco/Dashboard/GlaucoPatientsWithCompanion'
import { getCurrentClinic } from '@/utils/localStorageManager.js'

export default {
  name: 'Glauco',
  metaInfo: {
    title: 'Eyecare - Glauco'
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      totalPatients: 0,
      accessKey: ''
    }
  },
  components: {
    'v-new-access-key-modal': NewAccessKeyModal,
    'v-glauco-resume': GlaucoResume,
    'v-main-diseases': MainDiseases,
    'v-patient-quantity': PatientQuantity,
    'v-adherence-treatment': AdherenceTreatment,
    'v-patients-gender': PatientsGender,
    'v-glauco-patients': GlaucoPatients,
    'v-glauco-patients-with-companion': GlaucoPatientsWithCompanion
  },
  methods: {
    openAccessKeyModal() {
      this.$bvModal.show('new-access-key-modal')
    },
    changeTotalPatients(total) {
      this.totalPatients = total
    },
    action(ref) {
      const element = this.$refs[ref]
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    newAccessKey(code) {
      this.accessKey = code
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }

  .content {
    background-color: var(--neutral-000);
    border-radius: 8px;

    .content-data {
      padding: 10px 15px;
    }
  }
}
</style>
