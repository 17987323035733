<template functional>
  <div class="value-wrapper">
    <div class="description">{{ props.description }}</div>
    <div class="value" :class="`text-${props.color}`">
      <div class="prefix">{{ props.prefix }}</div>
      {{ props.value }}
      <div class="postfix mt-1">{{ props.postfix }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    postfix: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'default'
    }
  }
}
</script>

<style lang="scss" scoped>
.value-wrapper {
  .description {
    font-weight: 700;
    font-size: min(1vw, 14px);
  }
  .value {
    font-weight: 700;
    font-size: min(1.3vw, 18px);
    display: flex;
    align-items: center;
    gap: 5px;
    .prefix,
    .postfix {
      font-size: min(1vw, 14px);
    }

    span {
      font-weight: 700;
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 16px;
    }
  }
}
</style>
