<template>
  <div class="patient-form-container">
    <b-form-group>
      <label v-if="showLabel" for="patient_id">{{ label || 'Paciente' }} 
        <span v-if="!required" class="help">(opcional)</span>
      </label>
      <multiselect
        id="patient_id"
        v-model="patient"
        track-by="id"
        label="name"
        :placeholder="placeholder"
        :options="patients"
        :allowEmpty="false"
        :internal-search="false"
        :option-height="40"
        :showLabels="false"
        :showNoResults="true"
        class="with-border"
        :disabled="disabled"
        @search-change="debounceGetPatients"
        @select="onSelectPatient"
      >
        <template slot="singleLabel">
          <div class="w-100 pr-3">
            <Ellipsis>{{patient?.name}}</Ellipsis>
          </div>
        </template>
        <template v-if="!loading" slot="caret">
          <div class="chevron">
            <ChevronDown class="icon" v-if="!patient" />
            <Close class="clear-button" v-else @click="onSelectPatient(null)" />
          </div>
        </template>
        <template slot="option" slot-scope="{ option }">
          <div class="option-container">
            <div class="option-name">
              <Ellipsis>{{ option.name }}</Ellipsis>
            </div>
            <div class="option-container-props">
              <div class="option-props-birthday">{{ getBirthday(option.birthday) }}</div>
              <span class="props-pipe">|</span>
              <div class="option-props-cpf">CPF: {{ getCpf(option.cpf) }}</div>
            </div>
          </div>
        </template>
        <template slot="noOptions">
          <div>Digite para pesquisar seus pacientes...</div>
        </template>
        <template slot="noResult">
          <div>Nenhum paciente encontrado...</div>
        </template>
      </multiselect>
      <div v-if="error" class="custom-invalid-feedback">Campo obrigatório</div>
    </b-form-group>
    <b-spinner
      v-if="loading"
      class="loading"
      :class="{'custom-loading-class': customLoadingClass}"
      :variant="customLoadingClass ? 'primary' : 'light'"
      type="grow"
    />
  </div>
</template>
<script>
import { debounce } from 'lodash';
import moment from 'moment';
export default {
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  props: {
    value: Object,
    label: String,
    disabled: Boolean,
    required: Boolean,
    error: Boolean,
    placeholder: {
      type: String,
      default: 'Selecionar'
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    customLoadingClass: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.clinic = JSON.parse(localStorage.getItem('clinic'));
    this.debounceGetPatients = debounce(this.getPatients, 500);
  },
  mounted() {
    if (this.value && this.value.id) {
      if(!this.patients.find(p => p.id === this.value.id)) {
        this.patients.push(this.value);
      }
      this.patient = this.value;
    }
  },
  data() {
    return {
      clinic: null,
      patient: null,
      loading: false,
      patients: [],
    }
  },
  methods: {
    onSelectPatient(patient) {
      this.patient = patient;
      this.$emit('input', patient);
      this.$emit('select', patient);
    },
    getPatients(query) {
      this.loading = true;
      this.api.searchPatients(this.clinic.id, query)
        .then(res => (this.patients = res.data))
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false));
    },
    getBirthday(birthday) {
      if (!birthday)return 'Não informado'
      else if(!moment(birthday).isValid()) return 'Data nasc. inválida'
      return moment(birthday).format('(DD/MM/YYYY)');
    },
    getCpf(cpf) {
      if (!cpf) return 'Não informado';
      return cpf;
    }
  },
  watch: {
    value: function(newPatient, oldPatient) {
      if (newPatient && !oldPatient) {
        if(!this.patients.find(p => p.id === newPatient.id)) {
          this.patients.push(newPatient);
        }
      }
      this.patient = newPatient;
    }
  }
}
</script>
<style lang="scss" scoped>
.patient-form-container {
  position: relative;
  text-align: left !important;
  
  label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
  .help {
    margin-left: 5px;
  }

  .loading {
    position: absolute;
    top: 33px;
    right: 10px;
    z-index: 50;
  }
  .custom-loading-class {
    position: absolute;
    top: 3px !important;
    right: 12px !important;
    z-index: 50;
    transform: scale(-0.5) !important;
  }
  .option-container {
    display: flex;
    flex-direction: column;
    font-family: 'Nunito Sans';
  }
  .option-name {
    font-size: 1rem;
    color: var(--type-active);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .option-container-props {
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
  }
  .option-props-birthday {
    color: var(--type-placeholder);
  }
  .props-pipe {
    margin: 0 5px;
    color: var(--type-placeholder);
  }
  .option-props-cpf {
    color: var(--blue-500);
  }
  .chevron {
    z-index: 10;

    &.icon {
      stroke: var(--neutral-500);
    }
  }
  .clear-button {
    fill: var(--neutral-500) !important;
    stroke: none !important;
  }
}
</style>
