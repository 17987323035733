<template>
  <section id="glauco-summary-container">
    <div class="header">
      <div class="title d-flex">
        <span class="icon mr-2">
          <v-glauco-icon />
        </span>
        Resumo do Glauco
      </div>
      <div class="actions">
        <b-button
          :disabled="disabledButton"
          class="btn-code"
          size="lg"
          id="access-key-button"
          @click="openAccessKeyModal"
        >
          Gerar código premium
        </b-button>

        <b-tooltip
          v-if="disabledButton"
          target="access-key-button"
          placement="top"
          triggers="hover"
        >
          <span class="d-block">
            Você não possui créditos para gerar um código premium,
          </span>
          entre em contato com o suporte para adquirir mais créditos.
        </b-tooltip>
      </div>
    </div>
    <div class="divider" />
    <div class="values-container">
      <div class="pipe d-none" />
      <v-value-card
        description="Pacientes no Glauco"
        :value="totalPatients"
        postfix="pacientes"
      />

      <div class="pipe" />
      <v-value-card
        description="Códigos aguardando ativação"
        :value="totalCodesWaitingActivation"
        postfix="códigos"
        color="primary"
      />

      <div class="pipe" />
      <v-value-card
        description="Quantidade de códigos restantes"
        :value="totalCodesRemaining"
        postfix="códigos"
        color="success"
      />

      <div class="pipe" />
      <v-value-card
        description="Quantidade de códigos gerados"
        :value="totalCodesGenerated"
        postfix="códigos"
        color="warning"
      />
    </div>
  </section>
</template>

<script>
import GlaucoIcon from '@/components/Glauco/GlaucoIcon'
import ValueCard from '@/components/General/ValueCard'

export default {
  name: 'GlaucoResume',
  props: {
    clinicId: {
      type: String,
      required: true
    },
    openAccessKeyModal: {
      type: Function,
      required: true
    },
    newAccessKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      totalPatients: 0,
      totalCodesWaitingActivation: 0,
      totalCodesRemaining: 0,
      totalCodesGenerated: 0,
      totalCodesCredit: 0
    }
  },
  computed: {
    disabledButton() {
      return this.totalCodesRemaining === 0
    }
  },
  components: {
    'v-value-card': ValueCard,
    'v-glauco-icon': GlaucoIcon
  },
  async created() {
    await this.getGlaucoResume()
    await this.getTotalGlaucoCredits()
  },
  methods: {
    async getGlaucoResume() {
      try {
        const response = await this.api.glaucoResume(this.clinicId)
        this.totalPatients = response.data.glaucoPatients
        this.totalCodesWaitingActivation = response.data.codesWaitingActivation
        this.totalCodesGenerated = response.data.generatedCodes
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getTotalGlaucoCredits() {
      try {
        const response = await this.api.getTotalGlaucoCredits(this.clinicId)
        this.totalCodesCredit = response.data
        this.totalCodesRemaining =
          this.totalCodesCredit - this.totalCodesGenerated
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  },
  watch: {
    totalPatients: {
      handler(value) {
        this.$emit('change-total-patients', value)
      },
      immediate: true
    },
    newAccessKey: {
      handler(value) {
        if (value) {
          this.totalCodesGenerated += 1
          this.totalCodesRemaining -= 1
          this.totalCodesWaitingActivation += 1
          this.totalCodesGenerated += 1
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
#glauco-summary-container {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 24px;
  width: 100%;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
    .title {
      font-weight: 500;
      font-size: min(1.2vw, 16px);
      color: var(--dark-blue);
      margin-bottom: 0;

      .icon {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }
    .actions {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .btn-code {
        background-color: var(--blue-700);
        border-color: var(--blue-700);
        color: white;
        font-weight: 500;
        font-size: min(1.2vw, 16px);
        padding: 10px 20px;
        border-radius: 8px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    margin: 10px 0 15px 0;
    background-color: var(--neutral-200);
  }

  .values-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .pipe {
      width: 1px;
      height: 50px;
      margin: 0 10px;
      background-color: var(--neutral-200);
    }
  }
}
</style>
