<template>
  <div id="patients-gender-container">
    <CardHeader
      title="Pacientes por genêro e faixa etária"
      :periodType="null"
      :options="optionsGender"
      :showPeriod="false"
      @changeOption="changeOption"
    />

    <div class="chart">
      <VueApexCharts
        type="bar"
        ref="chart"
        width="100%"
        height="350%"
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>


<script>
import CardHeader from '@/layouts/InvoicingTiss/Dashboard/CardHeader'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'PatientsGender',
  components: {
    CardHeader,
    VueApexCharts
  },
  props: {
    clinicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      optionsGender: [
        {
          label: 'Todos',
          value: null
        },
        {
          label: 'Masculino',
          value: 'masculino'
        },
        {
          label: 'Feminino',
          value: 'feminino'
        }
      ],
      options: {
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        xaxis: {
          type: 'string',
          position: 'bottom',
          categories: [
            '0 a 5 anos',
            '5 a 15 anos',
            '15 a 20 anos',
            '20 a 30 anos',
            '30 a 50 anos',
            '50 a 70 anos',
            'Mais de 70 anos'
          ]
        }
      },
      series: [
        {
          name: 'Masculino',
          color: 'var(--blue-500)',
          type: 'bar',
          data: []
        },
        {
          name: 'Feminino',
          color: 'var(--neutral-500)',
          type: 'bar',
          data: []
        }
      ]
    }
  },
  async created() {
    await this.getUserAndGenderGlauco()
  },
  methods: {
    async getUserAndGenderGlauco() {
      try {
        const response = await this.api.getUserAndGenderGlauco(this.clinicId)
        this.$set(this.series, 0, {
          ...this.series[0],
          data: Object.values(response.data.dataMen)
        })

        this.$set(this.series, 1, {
          ...this.series[1],
          data: Object.values(response.data.dataWomen)
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async changeOption(option) {
      if (option.label === 'Todos') {
        this.$refs.chart.chart.showSeries('Masculino')
        this.$refs.chart.chart.showSeries('Feminino')
      } else if (option.label === 'Masculino') {
        this.$refs.chart.chart.showSeries('Masculino')
        this.$refs.chart.chart.hideSeries('Feminino')
      } else if (option.label === 'Feminino') {
        this.$refs.chart.chart.showSeries('Feminino')
        this.$refs.chart.chart.hideSeries('Masculino')
      }
    }
  }
}
</script>

<style lang="scss">
#patients-gender-container {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 1.5vh;
  width: 100% !important;
  height: 100% !important;
}
</style>
