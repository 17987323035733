<template>
  <b-col id="patient-quantity-container" cols="4">
    <b-row class="mb-3" v-for="(card, key) in cardsContent" :key="key">
      <v-patient-quantity-card
        :title="card.title"
        :value="card.value"
        :description="card.description"
        :action="card.action"
        :color="card.color"
      >
        <v-warning
          v-if="card.title === 'Pacientes inativos'"
          v-b-tooltip.hover
          title="Pacientes que não abrem o
          aplicativo por 14 dias ou mais"
          class="icon warning mt-1 ml-2"
        />
        <v-heart v-else class="icon heart ml-2 mt-1" />
      </v-patient-quantity-card>
    </b-row>
  </b-col>
</template>

<script>
import PatientQuantityCard from '@/components/Glauco/Dashboard/PatientQuantityCard'
import warning from '@/assets/icons/warning-stroke.svg'
import heart from '@/assets/icons/heart.svg'

export default {
  name: 'PatientQuantity',
  components: {
    'v-patient-quantity-card': PatientQuantityCard,
    'v-warning': warning,
    'v-heart': heart
  },
  props: {
    clinicId: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: true
    }
  },
  async created() {
    await this.getCountGlaucoPatientsWithCompanions()
  },
  data() {
    return {
      cardsContent: [
        // {
        //   title: 'Pacientes inativos',
        //   value: 26,
        //   description: 'paciente',
        //   action: this.goToPatientsTable,
        //   color: 'danger'
        // },
        {
          title: 'Pacientes com cuidadores',
          value: 0,
          description: 'paciente',
          action: this.goToPatientsWithCompanionTable,
          color: 'primary'
        }
      ]
    }
  },
  computed: {
    cardCompanionsPosition() {
      return this.cardsContent.findIndex(
        card => card.title === 'Pacientes com cuidadores'
      )
    }
  },
  methods: {
    goToPatientsTable() {
      this.action('patients')
    },
    goToPatientsWithCompanionTable() {
      this.action('patientsWithCompanions')
    },
    async getCountGlaucoPatientsWithCompanions() {
      try {
        const { data } = await this.api.getCountGlaucoPatientsWithCompanions(
          this.clinicId
        )
        this.cardsContent[this.cardCompanionsPosition].value = data.users
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss">
#patient-quantity-container {
  .row {
    height: 100%;
    // 47% is the height of the card
  }

  .icon {
    width: 17px;
    height: 17px;

    &.heart {
      fill: var(--blue-500);
    }
  }
}
</style>
