<template>
  <div class="patient-quantity-card">
    <div class="top-container">
      <div class="d-flex">
        <span class="title">{{ title }}</span>
        <slot></slot>
      </div>
      <b-button class="action-button mb-4" variant="link" @click="action">
        Ver pacientes
      </b-button>
    </div>

    <div class="main-container">
      <span class="value" :class="`text-${color}`">{{ value }} </span>
      <span class="description" :class="`text-${color}`">
        {{ computedDescription }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PatientQuantityCard',
  props: {
    title: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    value: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    computedDescription() {
      return this.value === 1 ? this.description : `${this.description}s`
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-quantity-card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 1.5vh;
  width: 100% !important;

  .top-container {
    display: flex;
    .title {
      font-size: min(1vw, 16px);
    }

    .action-button {
      font-size: min(1vw, 16px);
      margin-left: auto;
    }
  }

  .main-container {
    margin-bottom: 1vh;
    font-weight: 700;
    font-size: min(1.5vw, 24px);
  }
}
</style>
