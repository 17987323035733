<template>
  <div class="header">
    <div class="title d-flex">
      <span class="icon mr-2">
        <v-glauco-icon />
      </span>
      {{ title }}
    </div>
  </div>
</template>

<script>
import GlaucoIcon from '@/components/Glauco/GlaucoIcon'

export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  components: {
    'v-glauco-icon': GlaucoIcon
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
  .title {
    font-weight: 500;
    font-size: min(1.2vw, 16px);
    color: var(--dark-blue);
    margin-bottom: 0;

    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 17px;
        height: 17px;
      }
    }
  }
}
</style>
