<template>
  <div class="adherence-chart">
    <CardHeader
      :title="title"
      :periodType="periodType"
      :periodFormat="periodFormat"
      @changePeriod="changePeriod"
    />
    <b-row>
      <b-col cols="4">
        <div class="main-content">
          <h3>{{ adherenceData.percentage }}%</h3>
          <small
            class="percentage"
            :class="{
              positive: adherenceData.reference > 0,
              negative: adherenceData.reference < 0
            }"
          >
            {{ adherenceData.reference }}%
          </small>
          <span class="reference">Ref. ao último {{ typePeriod }} </span>
        </div>
      </b-col>
      <b-col cols="8">
        <div class="chart">
          <VueApexCharts
            type="area"
            width="100%"
            height="150%"
            :options="options"
            :series="series"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardHeader from '@/layouts/InvoicingTiss/Dashboard/CardHeader'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'AdherenceChart',
  components: {
    CardHeader,
    VueApexCharts
  },
  props: {
    adherenceData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    periodType: {
      type: String,
      required: true,
      validator(value) {
        return ['month', 'year'].includes(value)
      }
    },
    periodFormat: {
      type: String,
      required: true
    },
    colorGradient: {
      type: String,
      default: '#1A73E8'
    },
    strokeColor: {
      type: String,
      default: '#1A73E8'
    },
    categories: {
      type: Array,
      default: () => []
    },
    dataChart: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    series() {
      return [
        {
          name: this.title,
          data: this.dataChart
        }
      ]
    },
    typePeriod() {
      return this.periodType === 'month' ? 'mês' : 'ano'
    }
  },
  data() {
    return {
      options: {
        chart: {
          height: 350,
          type: 'area',
          background: 'transparent',
          zoom: {
            enabled: true
          }
        },
        fill: {
          colors: [this.colorGradient],
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          colors: [this.strokeColor],
          curve: 'straight'
        },
        grid: {
          show: false
        },
        yaxis: {
          show: false,
          labels: {
            formatter(val) {
              return val ? `${val.toFixed(2)}%` : '0%'
            }
          }
        },
        xaxis: {
          categories: this.categories
        }
      }
    }
  },
  methods: {
    changePeriod(value) {
      if (this.periodType === 'month') {
        this.$emit('changePeriodMonth', value)
        return
      }
      this.$emit('changePeriodYear', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.adherence-chart {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 1.5vh;
  width: 100% !important;

  .main-content {
    margin-top: 10%;
    display: flex;
    flex-direction: column;

    h3 {
      font-family: 'Red Hat Display';
      font-weight: 700;
      color: var(--neutral-700);
      font-size: min(3vw, 30px);
    }

    .percentage {
      margin-top: 7%;
      padding: 4px 8px;
      width: fit-content;
      border-radius: 16px;
      color: var(--neutral-000);
      font-weight: 600;
      &.negative {
        background-color: var(--states-red-soft) !important;
      }

      &.positive {
        background-color: var(--states-success) !important;
      }
    }

    .reference {
      margin-top: 7%;
      font-family: 'Red Hat Display';
      font-weight: 500;
      color: var(--type-active);
      font-size: min(1vw, 16px);
    }
  }
}
</style>
