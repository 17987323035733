<template>
  <b-row id="glauco-patient-filters">
    <b-col cols="6">
      <div class="search-container">
        <Search class="icon" />
        <b-form-input
          type="search"
          v-model="filters.patient"
          debounce="500"
          autocomplete="off"
          :placeholder="placeholder"
        />
      </div>
    </b-col>

    <!-- <b-col cols="4">
      <multiselect
        id="situation"
        v-model="filters.situation"
        :options="situations"
        label="label"
        class="with-border"
        :show-labels="false"
        placeholder="Pesquisar"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noResult"> Nenhum resultado </template>
      </multiselect>
    </b-col> -->

    <b-col cols="6">
      <multiselect
        id="eye-disease"
        label="label"
        v-model="filters.eyeDisease"
        :options="glaucoEyeDiseases"
        class="with-border"
        :show-labels="false"
        placeholder="Todos os tratamentos"
        @search-change="searchGlaucoEyeDiseasesDebounce"
        @select="clearOptions"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="noResult"> Nenhum resultado </template>
        <template slot="noOptions"> Digite para pesquisar </template>
      </multiselect>
    </b-col>
  </b-row>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Search from '@/assets/icons/search.svg'
import debounce from 'lodash/debounce'

export default {
  name: 'Filters',
  props: {
    placeholder: {
      type: String,
      default: 'Pesquisar'
    }
  },
  components: {
    Search,
    ChevronDown
  },
  created() {
    this.searchGlaucoEyeDiseasesDebounce = debounce(
      this.searchGlaucoEyeDiseases,
      500
    )
  },
  data() {
    return {
      searchGlaucoEyeDiseasesDebounce: Function,
      filters: {
        patient: null,
        situation: null,
        eyeDisease: null
      },
      situations: [
        { label: 'Todas as situações', value: null },
        { label: 'Ativo', value: 'active' },
        { label: 'Código enviado', value: 'sent-code' },
        { label: 'Código não ativado', value: '' },
        { label: 'Inativo', value: 'inactive' }
      ],
      glaucoEyeDiseases: []
    }
  },
  methods: {
    async searchGlaucoEyeDiseases(query) {
      if (query.length < 3) return
      try {
        const response = await this.api.searchGlaucoEyeDiseases(query)
        this.glaucoEyeDiseases = response.data.map(item => {
          return {
            label: item.disease,
            id: item.id
          }
        })
        this.glaucoEyeDiseases.unshift({
          label: 'Todos os tratamentos',
          id: null
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    clearOptions() {
      this.glaucoEyeDiseases = []
      this.glaucoEyeDiseases.push({
        label: 'Todos os tratamentos',
        id: null
      })
    }
  },
  watch: {
    filters: {
      handler(newValue) {
        this.$emit('change-filters', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  flex-direction: row;
  position: relative;

  .icon {
    position: absolute;
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    height: 100%;
    margin-left: 10px;
  }

  input {
    padding-left: 40px !important;
  }
}
</style>
