import Vue from 'vue'

export const openWhatsApp = (phone, message = '') => {
  const whatsappNumber = phone.replace(/\D/g, '')
  if (whatsappNumber.length < 10) {
    Vue.prototype.$toast.warning('Número de telefone sem DDD')
    return
  }
  window.open(
    `https://api.whatsapp.com/send?phone=55${whatsappNumber}&text=${window.encodeURIComponent(message)}`,
    '_blank'
  )
}

export const isCellphoneValid = (phone) => {
  const regex = new RegExp(/^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/)
  return regex.test(phone)
}
