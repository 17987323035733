<template>
  <b-modal
    id="new-access-key-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Gerar novo código premium</p>
      </div>
      <div>
        <v-close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="code">Código</label>
            <div class="input-copy">
              <b-form-input
                id="code"
                :value="code"
                readonly
                placeholder="Confirme a geração do código para visualizar"
              />
              <v-copy class="copy" @click="copyCode" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="divider" />

      <b-row>
        <b-col cols="6">
          <b-form-group>
            <v-patient-input
              required
              v-model="patient"
              :error="false"
              @select="selectPatient"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3" class="phone-col">
          <b-form-group>
            <b-form-input
              placeholder="(00) 00000-0000"
              v-mask="'(##) #####-####'"
              debounce="500"
              @change="changeCellPhone"
              v-model="cellphone"
            />
            <div v-if="!validCellphone" class="custom-invalid-feedback">
              Insira um número válido
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="phone-col">
          <b-button
            class="send-whatsapp-button"
            :disabled="!canSendMessage"
            @click="openWhatsApp(cellphone, whatsappMessage)"
          >
            <v-whatsapp class="whatsapp-icon" /> Enviar código
          </b-button>
        </b-col>
      </b-row>

      <div class="divider" />

      <div class="wrapper-button">
        <b-button variant="primary" :disabled="!canSave" @click="generateCode">
          Confirmar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import PatientInput from '@/components/General/PatientInput'
import copyIcon from '@/assets/icons/copy.svg'
import Whatsapp from '@/assets/icons/whatsapp-icon.svg'
import { openWhatsApp, isCellphoneValid } from '@/utils/phoneHelper'

export default {
  name: 'NewAccessKeyModal',
  components: {
    'v-close': Close,
    'v-copy': copyIcon,
    'v-whatsapp': Whatsapp,
    'v-patient-input': PatientInput
  },
  props: {
    clinic: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      code: '',
      patient: null,
      cellphone: '',
      validCellphone: true
    }
  },
  computed: {
    canSave() {
      return Boolean(this.patient)
    },
    canSendMessage() {
      return Boolean(this.canSave && this.code && this.validCellphone)
    },
    patientFirstName() {
      return this.patient?.name?.split(' ')[0]
    },
    whatsappMessage() {
      return `Olá, ${this.patientFirstName}! \n\nEstamos lhe enviando um código para utilizar o Glauco na versão premium: ${this.code}. Ative o código na aba “Minha Jornada".\n\nCaso ainda não possua o aplicativo você poderá baixá-lo nos links abaixo:\n\nApp Store: https://apps.apple.com/br/app/glauco/id1535646838 \n\nPlay Store: https://play.google.com/store/apps/details?id=com.eyecarehealth.glauco \n\n${this.clinic.name}.`
    }
  },
  methods: {
    openWhatsApp,
    clearModal() {
      this.code = ''
      this.patient = null
      this.cellphone = ''
      this.$bvModal.hide('new-access-key-modal')
    },
    selectPatient(patient) {
      this.cellphone = patient.cellphone
      this.changeCellPhone()
    },
    changeCellPhone(phone = this.cellphone) {
      this.validCellphone = isCellphoneValid(phone)
    },
    async generateCode() {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.generateCode(this.clinic.id, {
          prefix: 'EYE',
          quantity: 1
        })
        this.code = response.data.keys[0].key
        this.$toast.success('Código gerado com sucesso')
        this.$emit('new-access-key', this.code)
      } catch (error) {
        this.$toast.error('Erro ao gerar código')
      } finally {
        isLoading.hide()
      }
    },
    async copyCode() {
      if (!this.code) return
      try {
        await navigator.clipboard.writeText(this.code)
        this.$toast.success('Código copiado com sucesso')
      } catch (error) {
        this.$toast.error('Erro ao copiar código')
      }
    }
  }
}
</script>

<style lang="scss">
#new-access-key-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;

        .divider {
          width: 100%;
          height: 1px;
          margin: 10px 0 15px 0;
          background-color: var(--neutral-200);
        }

        .input-copy {
          position: relative;

          .copy {
            width: 24px;
            height: 24px;
            fill: var(--neutral-500);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 17px;
            cursor: pointer;
          }
        }

        .phone-col {
          margin-top: 3.7%;

          .send-whatsapp-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--states-success-soft-green);
            border: 1px solid var(--states-success-soft-green);
            color: var(--states-success-dark-green);
            font-weight: 600;
            border-radius: 8px;
            width: 100%;
            margin: auto 0;
            height: 60%;

            .whatsapp-icon {
              margin-top: 3%;
              margin-right: 5%;
              margin-bottom: 5px;
              border: 1px solid var(--states-success-dark-green);
              border-radius: 8px;
            }
          }
        }
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }
}
</style>
