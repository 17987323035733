<template>
  <section id="adherence-treatment-container">
    <b-row v-for="(chart, index) in dataCharts" :key="index">
      <v-adherence-chart
        :title="chart.title"
        :periodType="chart.periodType"
        :periodFormat="chart.periodFormat"
        :strokeColor="chart.strokeColor"
        :colorGradient="chart.colorGradient"
        :adherenceData="adherenceData[index]"
        :categories="chart.categories"
        :dataChart="chart.data"
        @changePeriodMonth="changePeriodMonth"
        @changePeriodYear="changePeriodYear"
      />
    </b-row>
  </section>
</template>

<script>
import AdherenceChart from '@/components/Glauco/Dashboard/AdherenceChart'

export default {
  name: 'AdherenceTreatment',
  components: {
    'v-adherence-chart': AdherenceChart
  },
  async created() {
    await this.adherenceTreatmentInmonth()
    await this.adherenceTreatmentInyear()
  },
  props: {
    clinicId: {
      type: String,
      required: true
    }
  },
  data() {
    this.moment.locale('pt-br')
    return {
      year: this.moment().year(),
      month: this.moment().format('YYYY-MM-DD'),
      dataCharts: [
        {
          title: 'Adesão ao tratamento no último mês',
          periodType: 'month',
          periodFormat: 'MMMM [de] YYYY',
          categories: [],
          data: []
        },
        {
          title: 'Adesão ao tratamento no último ano',
          periodType: 'year',
          periodFormat: 'YYYY',
          strokeColor: '#FF6B00',
          colorGradient: '#FF9900',
          categories: this.moment.months(),
          data: []
        }
      ],
      adherenceData: [
        {
          percentage: 0,
          reference: 0
        },
        {
          percentage: 0,
          reference: 0
        }
      ]
    }
  },
  methods: {
    async adherenceTreatmentInmonth() {
      try {
        const { data } = await this.api.glaucoAdherenceTreatmentInMonth(
          this.clinicId,
          this.month
        )
        this.setChartValues(data, 0)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async adherenceTreatmentInyear() {
      try {
        const { data } = await this.api.glaucoAdherenceTreatmentInyear(
          this.clinicId,
          this.year
        )
        this.setChartValues(data, 1)
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    setChartValues(data, index) {
      this.adherenceData[index].percentage = data.percentage
      this.dataCharts[index].data = data.data
      this.adherenceData[index].reference = data.reference
    },
    async changePeriodYear(year) {
      this.year = year
      await this.adherenceTreatmentInyear()
    },
    async changePeriodMonth(month) {
      this.month = month
      await this.adherenceTreatmentInmonth()
    }
  }
}
</script>

<style lang="scss" scoped>
#adherence-treatment-container {
  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }
  .row:last-child {
    margin-top: 10px;
  }
}
</style>
