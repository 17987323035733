<template>
  <section id="main-diseases-container">
    <div class="header">
      <div class="title">Principais doenças tratadas com o Glauco em %</div>
    </div>

    <div class="chart">
      <VueApexCharts
        type="donut"
        width="100%"
        height="130%"
        :options="options"
        :series="series"
      />
    </div>
  </section>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'MainDiseases',
  components: {
    VueApexCharts
  },
  async created() {
    await this.getMainDiseasesInGlauco()
  },
  props: {
    clinicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      series: [],
      options: {
        tooltip: {
          enabled: true,
          y: {
            formatter(val) {
              return parseFloat(val).toFixed(2) + '%'
            }
          }
        },
        labels: [],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          pie: {
            offsetX: -80,
            donut: {
              size: '70%',
              labels: {
                show: true,
                formatter(val) {
                  return `${parseFloat(val).toFixed(2)} %`
                },
                name: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: 'Red Hat Display',
                  fontWeight: 500,
                  color: 'var(--type-placeholder)'
                },
                value: {
                  fontFamily: 'Red Hat Display',
                  fontSize: '24px',
                  color: 'var(--type-active)',
                  fontWeight: 500,
                  formatter(val) {
                    return `${parseFloat(val).toFixed(2)} %`
                  }
                }
              }
            }
          }
        },
        legend: {
          show: true,
          fontFamily: 'Nunito Sans',
          position: 'right',
          fontWeight: 600,
          fontSize: 'min(1.2vw, 16px)',
          color: 'var(--type-placeholder)',
          horizontalAlign: 'right',
          labels: {
            colors: 'var(--type-placeholder)',
            useSeriesColors: false
          },
          formatter(seriesName, opts) {
            return [
              seriesName,
              ' - ',
              parseFloat(opts.w.globals.series[opts.seriesIndex]?.toFixed(2)),
              '%'
            ]
          }
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              legend: {
                show: false
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    onlyNumbers(numericString) {
      const numberParsed = numericString.replace(/[^0-9]/g, '')
      return parseFloat(numberParsed)
    },
    async getMainDiseasesInGlauco() {
      try {
        const { data } = await this.api.getMainDiseasesInGlauco(this.clinicId)
        this.options = {
          ...this.options,
          labels: data.map(disease => disease.disease)
        }
        const total = data.reduce(
          (acc, disease) => acc + parseInt(disease.__meta__.total),
          0
        )
        this.series = data.map(
          disease => (parseInt(disease.__meta__.total) / total) * 100
        )
      } catch (error) {
        this.$toast.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#main-diseases-container {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  height: 100%;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
    .title {
      font-weight: 500;
      font-size: min(2.6vw, 18px);
      color: var(--dark-blue);
      margin-bottom: 0;

      .icon {
        width: 17px;
        height: 17px;

        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

