<template>
  <section id="patients-table-section">
    <v-header title="Pacientes no Glauco" />
    <v-filters
      placeholder="Pesquisar paciente, CPF ou número de prontuário"
      @change-filters="changeFilters"
    />

    <div class="divider" />
    <v-patients-table :items="glaucoPatients" :fields="fields" />
    <div class="flex">
      <b-pagination
        v-if="glaucoPatients"
        class="float-right mt-1 mr-4 mb-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      />
    </div>
  </section>
</template>

<script>
import Header from '@/components/Glauco/Dashboard/Header'
import PatientsTable from '@/components/Glauco/Dashboard/PatientsTable'
import Filters from '@/components/Glauco/Dashboard/Filters'

export default {
  name: 'GlaucoPatients',
  components: {
    'v-header': Header,
    'v-patients-table': PatientsTable,
    'v-filters': Filters
  },
  props: {
    clinicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: {},
      currentPage: 1,
      perPage: 10,
      rows: 0,
      glaucoPatients: [],
      fields: [
        {
          key: 'id',
          visible: false
        },
        {
          key: 'name',
          label: 'Nome',
          visible: true,
          thStyle: { width: '30%' }
        },
        {
          key: 'medical_record_number',
          label: 'Prontuário',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-center'
        },
        {
          key: 'cellphone',
          label: 'Celular',
          visible: true,
          thStyle: { width: '15%' },
          thClass: 'text-center'
        },
        {
          key: 'userTreatments',
          label: 'Tratamento',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-center'
        },
        {
          key: 'status',
          label: 'Situação',
          visible: true,
          thStyle: { width: '15%' },
          thClass: 'text-center'
        },
        {
          key: 'profile',
          label: 'Perfil glauco',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-right'
        }
      ]
    }
  },
  async created() {
    await this.getGlaucoPatients()
  },
  methods: {
    async getGlaucoPatients() {
      try {
        const { data } = await this.api.getGlaucoPatients(
          this.clinicId,
          this.currentPage,
          this.filters
        )
        this.rows = data.total
        this.currentPage = data.page
        this.glaucoPatients = []
        data.data.forEach(patient => {
          this.glaucoPatients.push({
            ...patient,
            userTreatments: patient.userTreatments.map(
              treatment => treatment.disease
            ),
            status: patient.accessKey?.revoked_at ? 'inactive' : 'active',
            tdClass: 'text-right'
          })
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async changeFilters(filters) {
      this.filters = filters
      await this.getGlaucoPatients()
    }
  },
  watch: {
    async currentPage() {
      await this.getGlaucoPatients()
    }
  }
}
</script>

<style lang="scss">
#patients-table-section {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  height: 100%;

  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 10px 0 15px 0;
    background-color: var(--neutral-200);
  }
}
</style>
