<template>
  <section id="patients-table-section">
    <v-header title="Pacientes com cuidadores" />
    <v-filters
      placeholder="Pesquisar paciente, cuidador ou número de prontuário"
      @change-filters="changeFilters"
    />

    <div class="divider" />
    <v-patients-table :items="glaucoPatients" :fields="fields" />
    <div class="flex">
      <b-pagination
        v-if="glaucoPatients"
        class="float-right mt-1 mr-4 mb-3"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      />
    </div>
  </section>
</template>

<script>
import Header from '@/components/Glauco/Dashboard/Header'
import PatientsTable from '@/components/Glauco/Dashboard/PatientsTable'
import Filters from '@/components/Glauco/Dashboard/Filters'
import { getCurrentClinic } from '@/utils/localStorageManager.js'

export default {
  name: 'GlaucoPatientsWithCompanion',
  components: {
    'v-header': Header,
    'v-patients-table': PatientsTable,
    'v-filters': Filters
  },
  props: {
    clinicId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      filters: {},
      currentPage: 1,
      perPage: 10,
      rows: 0,
      glaucoPatients: [],
      fields: [
        {
          key: 'id',
          visible: false
        },
        {
          key: 'name',
          label: 'Nome',
          visible: true,
          thStyle: { width: '20%' }
        },
        {
          key: 'companion',
          label: 'Cuidador',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-center'
        },
        {
          key: 'cellphone',
          label: 'Contato do cuidador',
          visible: true,
          thStyle: { width: '15%' },
          thClass: 'text-center'
        },
        {
          key: 'email',
          label: 'Email',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-center'
        },
        {
          key: 'companion_cpf',
          label: 'CPF do cuidador',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-center'
        },
        {
          key: 'status',
          label: 'Situação',
          visible: true,
          thStyle: { width: '15%' },
          thClass: 'text-center'
        },
        {
          key: 'profile',
          label: 'Perfil glauco',
          visible: true,
          thStyle: { width: '12%' },
          thClass: 'text-right'
        }
      ]
    }
  },
  async created() {
    await this.getGlaucoPatientsWithCompanions()
  },
  methods: {
    async getGlaucoPatientsWithCompanions() {
      try {
        const response = await this.api.getGlaucoPatientsWithCompanions(
          this.clinicId,
          this.currentPage,
          this.filters
        )
        this.rows = response.data.total
        this.currentPage = response.data.page
        this.glaucoPatients = []
        response.data.data.forEach(patient => {
          this.glaucoPatients.push({
            name: patient.name,
            companion: patient.companionsTo[0].fromUser.name,
            email: patient.companionsTo[0].fromUser.email,
            companion_cpf: patient.companionsTo[0].fromUser.cpf,
            cellphone: patient.companionsTo[0].fromUser.cellphone,
            status: 'active',
            tdClass: 'text-right'
          })
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async changeFilters(filters) {
      this.filters = filters
      this.$set(this.filters, 'companion', true)
      await this.getGlaucoPatientsWithCompanions()
    }
  },
  watch: {
    async currentPage() {
      await this.getGlaucoPatientsWithCompanions()
    }
  }
}
</script>
