<template>
  <b-table
    :items="items"
    :fields="visibleFields"
    id="table-glauco-patients"
    primary-key="id"
    show-empty
    borderless
    responsive
    empty-text="Nenhum paciente encontrado"
  >
    <template v-slot:cell(name)="data">
      <div class="d-flex align-items-center name-container">
        <Avatar :src="data.item.picture" />
        <span class="ml-2 name">{{ data.item.name }} </span>
        <span class="ml-1 age" v-if="data.item.birthday">
          ({{ moment().diff(data.item.birthday, 'years') }} anos)
        </span>
        <span class="icon" v-if="!data.item.companion_cpf">
          <GlaucoIcon class="logo-glauco" />
        </span>
      </div>
    </template>

    <template v-slot:cell(medical_record_number)="data">
      <b-button
        class="ml-4"
        v-if="data.item.bi_id"
        variant="link"
        @click="goPatient(`/pacientes/${data.item.bi_id}`)"
      >
        <span class="medical-record">
          {{ data.item.medical_record_number }}
        </span>
        <Export />
      </b-button>
      <span v-else class="text-center"> - </span>
    </template>

    <template v-slot:cell(cellphone)="data">
      <div
        class="d-flex align-items-center justify-content-center phone-container"
        v-if="data.item.cellphone"
      >
        <span class="default-td-text">{{ data.item.cellphone }}</span>
        <WhatsAppIcon
          class="whatsapp-icon ml-2"
          @click="openWhatsApp(data.item.cellphone)"
        />
      </div>
    </template>

    <template v-slot:cell(userTreatments)="data">
      <span class="d-flex mx-auto default-td-text">
        {{
          data.item.userTreatments.length >= 1
            ? data.item.userTreatments.join(' | ')
            : '-'
        }}
      </span>
    </template>

    <template v-slot:cell(status)="data">
      <span class="status d-flex mx-auto" :class="data.item.status">
        {{ parseStatus(data.item.status) }}
      </span>
    </template>

    <template v-slot:cell(profile)="data">
      <b-button
        class="ml-5"
        size="lg"
        variant="link"
        @click="goPatient(`dashboard/paciente/${data.item.id}`)"
      >
        Ver perfil
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { openWhatsApp } from '@/utils/phoneHelper.js'

export default {
  name: 'PatientsTable',
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    Export: () => import('@/assets/icons/export.svg'),
    WhatsAppIcon: () => import('@/assets/icons/whatsapp-icon.svg'),
    GlaucoIcon: () => import('@/components/Glauco/GlaucoIcon')
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    }
  },
  methods: {
    openWhatsApp,
    parseStatus(status) {
      return {
        'not-sent': 'Não enviado',
        'not-active': 'Cód. não ativado',
        'sent-code': 'Cód. enviado',
        active: 'Ativo',
        inactive: 'Inativo'
      }[status]
    },
    goPatient(url) {
      const route = this.$router.resolve(url)
      window.open(route.href, '_blank')
    }
  }
}
</script>

<style lang="scss">
#table-glauco-patients {
  thead {
    tr {
      border-bottom: 1px solid var(--neutral-200);
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid var(--neutral-200);
      td {
        .default-td-text {
          font-family: 'Nunito Sans';
          font-weight: 400;
          font-size: min(1.2vw, 16px);
          color: var(--type-active);
        }
        .name-container {
          font-weight: 400;
          .name {
            font-size: min(1.2vw, 16px);
            color: var(--type-active);
          }

          .age {
            font-size: min(1vw, 14px);
            color: var(--type-placeholder);
          }

          .icon {
            margin-left: 8px;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .phone-container {
          .whatsapp-icon {
            cursor: pointer;
          }
        }

        .status {
          color: var(--type-active);
          font-size: min(1.5vh, 12px);
          font-weight: 600;
          border: 1px solid transparent;
          padding: 4px 8px;
          width: fit-content !important;
          border-radius: 16px;

          &.active {
            background-color: var(--states-success-soft-green);
          }

          &.inactive {
            background-color: #ffd9d6;
          }

          &.not-active {
            background-color: var(--light-orange-200);
          }

          &.sent-code {
            background-color: var(--blue-100);
          }
        }
      }
    }
  }

  .medical-record {
    text-decoration: underline;
  }
}
</style>
