<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_969_90723"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8996 17.2121C23.3045 15.7612 24.0046 13.8799 24 12.0001C24.0047 10.1202 23.3046 8.23888 21.8996 6.78788C21.8996 6.78788 17.6687 2.58667 17.2682 2.18557C14.3586 -0.728526 9.64135 -0.728523 6.73181 2.18557C6.32563 2.59239 2.10042 6.78788 2.10042 6.78788C0.69663 8.23764 -0.00350702 10.117 1.33266e-05 11.9953C8.38805e-06 11.9969 3.94585e-06 11.9984 0 12C3.91785e-06 12.0016 8.32603e-06 12.0032 1.32248e-05 12.0047C-0.00350047 13.883 0.696637 15.7624 2.10043 17.2121C2.10043 17.2121 6.32566 21.4076 6.73181 21.8144C9.64135 24.7285 14.3587 24.7285 17.2682 21.8144C17.6744 21.4076 21.8521 17.1646 21.8996 17.2121Z"
        fill="#00C2FF"
      />
    </mask>
    <g mask="url(#mask0_969_90723)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.8996 17.2121C23.3045 15.7612 24.0046 13.8799 24 12.0001C24.0047 10.1202 23.3046 8.23888 21.8996 6.78788C21.8996 6.78788 17.6687 2.58667 17.2682 2.18557C14.3586 -0.728526 9.64135 -0.728523 6.73181 2.18557C6.32563 2.59239 2.10042 6.78788 2.10042 6.78788C0.69663 8.23764 -0.00350702 10.117 1.33266e-05 11.9953C8.38806e-06 11.9969 3.94585e-06 11.9984 0 12C3.91785e-06 12.0016 8.32603e-06 12.0032 1.32248e-05 12.0047C-0.00350047 13.883 0.696637 15.7624 2.10043 17.2121C2.10043 17.2121 6.32566 21.4076 6.73181 21.8144C9.64135 24.7285 14.3587 24.7285 17.2682 21.8144C17.6744 21.4076 21.8521 17.1646 21.8996 17.2121Z"
        fill="#00C2FF"
      />
      <path
        d="M9.65023 9.88544C10.948 8.52215 13.052 8.52215 14.3498 9.88544L31.3725 27.7679C32.6703 29.1312 32.6703 31.3416 31.3725 32.7048L14.3498 50.5873C13.052 51.9506 10.948 51.9506 9.65023 50.5873L-7.37254 32.7048C-8.67029 31.3416 -8.67029 29.1312 -7.37254 27.7679L9.65023 9.88544Z"
        fill="#FF6B00"
      />
      <path
        d="M9.67757 -26.6761C10.9753 -28.0394 13.0794 -28.0394 14.3771 -26.6761L31.3999 -8.79357C32.6976 -7.43029 32.6976 -5.21997 31.3999 -3.85668L14.3771 14.0258C13.0794 15.3891 10.9753 15.3891 9.67757 14.0258L-7.34521 -3.85668C-8.64295 -5.21997 -8.64295 -7.43029 -7.34521 -8.79357L9.67757 -26.6761Z"
        fill="#305BF2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3196 11.9474L14.3409 14.0261C13.0431 15.3894 10.9391 15.3894 9.64134 14.0261L7.67969 11.9654L9.65845 9.88672C10.9562 8.52344 13.0602 8.52344 14.358 9.88672L16.3196 11.9474Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GlaucoIcon'
}
</script>
